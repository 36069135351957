/* 
::::::::::::::::::::::::::::::::::::::::::::::::::::::
                    DEFAULT STYLING
::::::::::::::::::::::::::::::::::::::::::::::::::::::
*/
:root { 
  --text-color: #414c5d;
  --text-size-small: .25rem;
  --text-size-big: 1.5rem;
  --text-size-bigger: 2rem;
  --text-color-faded: #9CA4AD;

  --default-font-family: "Poppins Regular",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';

  --font-light: "Poppins Light", var(--default-font-family);

  --color-primary: #0E1A2A;
  --color-secundary: #1991eb;
  --color-ternary: #fefefe;
  --color-body: #F7F9FC;

  --success-color: #36af47;
  --error-color: #ed1c24;
  --warning-color: #f7981c;

  --default-color: #343e4d;

}

* { 
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body { 
  height: 100vh;
  width: 100vw;
  overflow-y: hidden!important;
  overflow-x: hidden!important;
  font-family: "Poppins Regular",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'!important;
}


.id-me { 
  border: 2px solid magenta!important;
}

@font-face {
  font-family: "Poppins Bold";
  src: url(./res/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: "Poppins Regular";
  src: url(./res/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins Light";
  src: url(./res/fonts/Poppins-Light.ttf);
}

@font-face {
  font-family: "Poppins Thin";
  src: url(./res/fonts/Poppins-Thin.ttf);
}

/* 
::::::::::::::::::::::::::::::::::::::::::::::::::::::
                  LAYOUT
::::::::::::::::::::::::::::::::::::::::::::::::::::::
*/
.container-front-page { 
  height: 100vh;
  display: grid;
  grid-template-rows: minmax(3rem,7%) 1fr;
  grid-template-columns: 1fr 1fr;
}
@media only screen and (max-width: 620px) { 
  .container-front-page { 
      display: flex;
      flex-direction: column;
      height: 100%;
  }
}

.main-content { 
  background-color: var(--color-ternary);
  grid-column: 1 / 3;
}

.auth-container { 
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
}
.login-container { 
  background: url(./res/img/login_background.jpg);
  background-size: cover;
}
.registration-container { 
  background: url(./res/img/sign_up_background.jpg);
  background-size: cover;
}
.new-request-container { 
  background: url(./res/img/fast_request_background.jpg);
  background-size: cover;
}
@media only screen and (max-width: 625px) { 
  .auth-container { 
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
  }
}

.form-container { 
  grid-column: 2/3;
  justify-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 4em;
}
@media only screen and (max-width: 1024px) { 
  .form-container { 
      grid-column: 1/3;
  }
}
@media only screen and (max-width: 625px) { 
  .form-container{ 
      padding: 1em 0.2em;
      width: 100%;
  }
}

.auth-form { 
  padding: 2em 2em;
  margin: 1em 0;
  text-align: center;
}

.container-internal-page { 
  height: 100vh;
  position:relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(10%, 20%) 1fr;
}
@media only screen and (max-width: 1024px) { 
  .container-internal-page { 
      display: block;
      height: 100vh;
  }
}

.internal-main-content { 
  grid-column: 2/3;
  background-color: var(--color-body); 
  height: 100%;
  overflow-y: auto;
}
.internal-side-nav { 
  grid-column: 1/2;
}
.internal-main-content, .internal-side-nav-container { 
  display: grid;
  grid-template-rows: minmax(5%,7%) 1fr;
}
@media only screen and (max-width: 1024px) { 
  .internal-side-nav-container { 
      display: block;
      position: absolute;
      width: 45vw;
      height: 100vh;
      right: 0;
      top: 7%;
      z-index: 3;
      transform: translateX(50vw);
      transition: transform ease-in-out .5s;
  }
  .internal-side-nav-container.open { 
      transform: translateX(0);
  }
}
@media only screen and (max-width: 768px)  { 
  .internal-side-nav-container { 
      top: 5%;
  }
}
@media only screen and (max-width: 425px){ 
  .internal-side-nav-container { 
      transform: translateX(100vw);
      width: 80vw;
  }
}

.internal-side-nav .app-name { 
  grid-row: 1/2;
}



/* :::::::::::::::::::::::::: NAVIGATION :::::::::::::::::::::::::::: */
.front-page-nav-container { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: 1 / 3;
  background-color: var(--color-primary);
  position: relative;
  z-index: 2;
}

.front-page-nav > * { 
  display: inline-block;
  vertical-align: middle;
  padding: .2em 1em;
}

.language-selector { 
  float: right;
}
.language-selector > span:nth-of-type(odd) { 
  cursor: pointer;
}

.responsive-logo {
  display: none;
}

.language-selector .selected { 
  text-decoration: underline;
  background-color: var(--color-secundary);
  padding: .2em;
  border-radius: 2px;
}
@media only screen and (max-width: 625px) {
  .front-page-nav-container { 
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-height: 3.5em;
      padding: .5em;
  }
  .front-page-nav { 
      position: absolute;
      height: 100vh;
      z-index: 1;
      background-color: #0E1A2A;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 3em;
      transform: translateX(100vw);
      transition: all 0.5s ease-in-out;
  }
  .front-page-nav.open { 
      transform: translateX(0vw);
  }
  .front-page-nav img { 
      display: none;
  }

  .nav-items > a, .language-selector{ 
      display: block;
      float: none;
  }
  .language-selector { 
      align-self: center;
  }
  .responsive-logo{ 
      display: block;
  }
}

/* 
::::::::::::::::::::::::::::::::::::::::::::::::::::::
                  AUTH PAGES
::::::::::::::::::::::::::::::::::::::::::::::::::::::
*/

/* :::::::::::::::::::::::::: NAVIGATION :::::::::::::::::::::::::::: */
.nav-items a { 
  text-decoration: none;
  text-transform: none;
  color: white;
  padding: .5em 1em;
}

.nav-items a:hover { 
  color: var(--color-secundary);
}

.hamb-btn-container { 
  width: 2.5em;
  height: 2.5em;
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  right: 0;
  z-index: 4;
}
.hamb-btn-container:hover {
  cursor: pointer;
}
@media only screen and (max-width:625px) {
  .hamb-btn-container{ 
      display: flex;
  }
}

.hamb-btn-container .hamb-btn-lines,
.hamb-btn-container .hamb-btn-lines::before,
.hamb-btn-container .hamb-btn-lines::after { 
  background-color: white;
  height: .2em;
  width: 2em;
  transition: all .5s ease-in-out;
}
.hamb-btn-container .hamb-btn-lines::before,
.hamb-btn-container .hamb-btn-lines::after  { 
  content: "";
  position: absolute;
}
.hamb-btn-container .hamb-btn-lines::before { 
  transform: translateY(.5rem);
}
.hamb-btn-container .hamb-btn-lines::after { 
  transform: translateY(-.5rem);
}

.hamb-btn-container.open .hamb-btn-lines { 
  background: transparent!important;
}
.hamb-btn-container.open .hamb-btn-lines::after { 
  transform: rotate(45deg) ;
}
.hamb-btn-container.open .hamb-btn-lines::before { 
  transform: rotate(-45deg) ;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.respon
/* :::::::::::::::::::::::::: FORMS :::::::::::::::::::::::::::: */
.iconed-input > * { 
  display: inline-block!important;
  vertical-align: center!important;
}

.iconed-input { 
  display: flex!important;
}

.iconed-input img { 
  background-color: black;
  padding: .5em 1em;
  max-width: 6rem;
  max-height: 6rem;
}

.dark-form { 
  background-color: rgba(0, 0, 0, 0.644);
}

.dark-form .form-header { 
  color: orange;
  font-weight: bold;
  padding: 0 0 1em 0;
}

.dark-input, .dark-select, .dark-textarea { 
  color: white!important;
  border: none!important;
  border-radius: 0!important;
  outline: none!important;
  padding: 1.3em .4em!important;
  background-color: rgba(0,0,0,1)!important;
  margin-left: 0px;
}
.dark-textarea {
  padding: .2em .6em!important;
  resize: none!important;
}
.dark-select::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.faded-button { 
  padding: .2em .6em;
}
.faded-button.success, .faded-button.btn-green {
  background-color: rgba(52, 170, 68, .1);
  color: #39b54a;
}
.btn-green:hover, .btn-green-active:hover {
  background-color: rgba(52, 170, 68, 1);
  color: white;
}

.faded-button.error, .faded-button.btn-error { 
  background-color: rgba(221,23,30, .1);
  color: rgb(248,82,88);
}
.btn-error:hover, .btn-error-active:active {
  background-color: rgba(221,23,30, 1);
  color: white;
}

.faded-button.btn-yellow { 
  background-color: rgba(247,152,28, .1);
  color: rgb(247,152,28);
}
.btn-yellow:hover {
  background-color: rgba(247,152,28, 1);
  color: white;
}

.faded-button.btn-error-active { 
  background-color: #dd171e !important;
  color: white;
}
.faded-button.btn-green-active {
  background-color: rgba(52, 170, 68, 1);
  color: white;
}
.faded-button:hover {
  cursor: pointer;
}

.dark-form input:-webkit-autofill,
.dark-form input:-webkit-autofill:hover, 
.dark-form input:-webkit-autofill:focus, 
.dark-form input:-webkit-autofill:active
{
-webkit-box-shadow: 0 0 0 30px black inset !important;
}
.dark-form input:-webkit-autofill
{
-webkit-text-fill-color: white!important;
}

.inline-select-container { 
  display: flex;
}
.inline-select-container > * { 
  margin-right: 2em;
}

.dark-file-input { 
  padding: 0;
  color: white;
  background-color: black;
}
.dark-file-input > * { 
  float: left;
}
.dark-file-input input[type="file"]::-webkit-file-upload-button { 
  background-color:rgb(255, 136, 0);
  border: none;
  outline: none;
  color: white;
  padding: .5em .6em;
}

.dark-select { 
  padding: 0 1em!important;
}

.hidden-dropdown-container{
  top: 1.4rem;
  margin: 0!important;
  padding: 0!important;
  width: 1px!important;
  background-color: transparent!important;

}
.hidden-dropdown { 
  background-color: transparent!important;
  border: none!important;
  z-index: 100!important;
}

.dark-input:focus, .dark-input:active { 
  border: none!important;
  outline: none!important;
  box-shadow: none!important;
  color: white!important;
}

.form-buttons { 
  margin-top: 3em;
}

.form-buttons > * { 
  display: inline-block;
  vertical-align: center;
}

.paged-form { 
  max-height: 75vh;
  position: relative;
  overflow-y: auto!important;
}

.paged-form .form-buttons,
.paged-form .page-indicators-container { 
  /* position: absolute; */
  bottom: 0;
  /* padding: 2rem 2rem; */
  left: 0;
  width: 100%;
}
/* .paged-form .page-indicators-container { 
  bottom: 3rem;
} */

@media only screen and (max-width: 625px) { 
  .form-buttons { 
      display: flex;
      flex-direction: column;
      position: relative!important;
  }
  .form-buttons > * { 
      display: block;
      width: 100%;
      margin-top: .5em;
  }
  .form-buttons a:nth-child(1){
      order: 3;
     
  }
  .form-buttons button:nth-child(2){
      order:1;
    
  }
  .form-buttons a:nth-child(3),.form-buttons button:nth-child(3){
      order:2;
  }
  .paged-form { 
      overflow-y: auto;
  }
  .paged-form .page-indicators-container { 
      display: none;
  }
}

.button { 
  padding: .2em 2em;
  background-color: var(--color-primary);
  color: white;
  outline: none;
  border: none;
  font-family: "Poppins Regular",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
}

.button:hover{
  cursor: pointer;
}

.button:focus, .button:active { 
  outline: none;
  border: none;
}

.link-button { 
  background-color: transparent;
  text-decoration: underline;
}

.link-button:hover { 
  color: orange;
  
}

.default-button { 
  background-color: var(--default-color);
}
.default-button:hover{
  background-color: var(--color-primary);
}

.btn-link { 
  color: white;
}

.dark-check-container { 
  color: white!important;
  text-align: start!important;

}
.custom-checkbox .dark-check.custom-control-input:checked ~ .dark-label.custom-control-label::before{
  background-color:orange ;
}

#dateInput::-webkit-calendar-picker-indicator,
#dateInput[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
  color: white;
  background-color: white;
}





.form-page { 
  position: relative;
  /* min-height: 25em; */

  overflow-y: auto;
  overflow-x: hidden;
  /* transform: translateX(50%); */
}
@media only screen and (max-width: 625px) { 
  .form-page { 
      overflow-y: hidden;
      min-height: none;
      height: auto;
  }
}

.page-indicators-container { 
  text-align: center;
}
.page-indicator { 
  display: inline-block;
  width: .7rem;
  height: .7rem;
  margin: 0 .4rem;
  border-radius: 50%;
  background-color: rgba(255, 166, 0, 0.3);
}
.page-indicator.active { 
  background-color: orange;
}

.page-indicators-container.blue-version .page-indicator { 
  background-color: rgba(0,0,0,.2);
}
.page-indicators-container.blue-version .page-indicator.active { 
  background-color: var(--color-secundary);
}


/* 
::::::::::::::::::::::::::::::::::::::::::::::::::::::
                  INTERNAL APP
::::::::::::::::::::::::::::::::::::::::::::::::::::::
*/
.internal-side-nav-container { 
  background: url(./res/img/login_background.jpg);
  background-size: cover;
  font-family: var(--default-font-family);
  color: var(--text-color-faded);
  font-size: .9rem;
}
.internal-side-nav { 
  padding: .7rem 0;
  background-color: rgba(41, 48, 66, .8);    
}

.internal-main-content-nav { 
  box-shadow: 0 0 2rem 0 rgb(41 48 66 / 10%);
  background-color: white;
  display: table;
  padding: 0 .8em;
  font-size: .9em;
}
.internal-main-content-nav > div { 
  display: table-cell;
  vertical-align: middle;
}

.user-info > span { 
  font-weight: 600;
  margin-left: .4em;
}

.app-name > * { 
  display: table-cell;
  vertical-align: middle;
}
.app-name { 
  display: table;
  background-color: #363D4F;
  color: #414C5D;
}

.internal-side-nav .nav-item { 
  padding: .6em .9em;
}
.internal-side-nav .nav-item:hover { 
  color: white;
  cursor: pointer;
}
.internal-side-nav .sub-items { 
  padding-left: 4em;
}
.internal-main-content-nav .language-selector > span { 
  color: var(--text-color)!important;
}
.internal-main-content-nav .language-selector > span.selected { 
  color: white!important;
}
@media only screen and (max-width:1024px) {
  .internal-main-content-nav  { 
      z-index: 4;
  }
  .internal-main-content-nav .language-selector { 
      display: none;
  }
  .internal-main-content-nav .hamb-btn-container{ 
      display: table-cell;
  }
  .internal-main-content-nav .hamb-btn-container .hamb-btn-lines,
  .internal-main-content-nav .hamb-btn-container .hamb-btn-lines::before,
  .internal-main-content-nav .hamb-btn-container .hamb-btn-lines::after { 
      background-color: var(--text-color);
  }

  .internal-side-nav  { 
      height: 100%;
  }
  
  .app-name { 
      display: none;
      text-align: center;
  }
}

.collapsible { 
  transition: max-height 0.5s ease-in-out;
  max-height: 0%;
  overflow: hidden;
}

.nav-item > * { 
  vertical-align: middle;
}
.nav-item span { 
  margin-left: 1rem;
}

.table-btn-container { 
  display: inline-block;
  width: 5.6rem;
  vertical-align: middle;
}

.table-icon-container { 
  float: right;
  margin-right: 1em;
}

.table-action-btn { 
  border-radius: 2px;
  margin-right: 1em;
  width: 100%;
}

.table-icon { 
  float: right;
  vertical-align: middle;
  position: relative;
  top: .8em;
  right: 1em;

}

/* :::::::::::::::::::::::::: MAIN-DISPLAY :::::::::::::::::::::::::::: */
.main-display-container { 
  height: 100%!important;
  width: 100%;
  padding: 1em;
}

.card-display-container { 
  padding: 1em .9em;
}

.elevated-container, .custom-card{ 
  box-shadow: 0 0 0.875rem 0 rgb(41 48 66 / 5%);
  background-color: white;
  border-radius: .25rem;
  /* padding: .7em 0em; cmt*/
}


.card-display-container, .card-display-container > * { 
  z-index: 1;
}

.elevated-contaniner-title { 
  display: inline-block;
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 1em;
  padding: 1em .9em;
}


.table, .table-pages { 
  font-size: .825rem;
}

/* :::::::::::::::::::::::::: FILTERS AND STATS (HOSTS) :::::::::::::::::::::::::::: */
.stats-container { 
  display: flex;
  margin-bottom: 1em;
}

.filters-container { 
  padding: 1em 2em;

}
.filters-container h3 { 
  float: left;
}
.filters-container div:nth-child(1){ 
  float: right;
}

.stats-container > div { 
  display: inline-block;
}
.stats-container .filters-container { 
  width: 80%;
}
.stats-container .stats-day-container { 
  width: 20%;
  margin-left: 1.5%;
}
.stats-container .stats-subscript { 
  float: right;
}
@media only screen and (max-width: 768px) { 
  .stats-container .stats-day-container { 
      display: none;
  }
  .stats-container .filters-container { 
      width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .stats-container .stats-subscript { 
      float: none;
  }
}

.filters-form-container { 
  margin-top: 4em;
}
.filters-form-container > * { 
  float: left;
  margin-top: 1em;
}
.filters-container form:nth-child(1) { 
  margin-right: 2em;
}
@media only screen and (max-width: 425px) { 
  .filters-container { 
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  }
  .filters-container > * { 
      display: block;
      text-align: center;
  }
  .filters-form-container { 
      display: none;
  }
}

.stats-day-container { 
  padding: 1em;
}


/* :::::::::::::::::::::::::: TABLE :::::::::::::::::::::::::::: */
.table-container { 
  position: relative;
  min-height: 45%!important;
  padding-bottom: 4em;
}



.striped-table { 
  width: 100%;
  bottom: 0;   
}
.striped-table thead > tr { 
  font-size: .825rem;
  font-weight: 400;
}
.striped-table th:first-child, .striped-table td:first-child { 
  padding-left: 1em;
}
.striped-table tr { 
  line-height: 2.625em;
}
.striped-table td { 
  font-size: .9em;
}
.striped-table > tbody:hover { 
  cursor: pointer;
}
.striped-table tbody > tr:nth-child(2n+1) { 
  border-top: 1px solid #DEE6ED;
  border-bottom: 1px solid #DEE6ED;
}
.striped-table tbody > tr:nth-child(2n+1) > td, .striped-table tbody > tr:nth-child(2n+1) > th {
  background-color: #F4F7F9;
}

.striped-table .long-td { 
  max-width: 25rem;
  padding-right: 1em;
}

.striped-table.default-line-height tr { 
  line-height:normal;
}
.striped-table.default-line-height td{ 
  line-height:normal;
  padding-top: .5em;
  padding-bottom: .5em;
}

.table-navigation { 
  display: table;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: .8em;
  align-self: flex-end;
  justify-self: flex-end;
  z-index: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.082);
}
.table-navigation > * { 
  display: table-cell;
  vertical-align: middle;
  padding: .8em 1em;
}

.navigation-container ul { 
  margin: 0!important;
}

/* :::::::::::::::::::::::::: ALERT MESSAGE CONTAINER :::::::::::::::::::::::::::: */
.alert-card { 
  background-color: white;
  border: 1px solid #eeeeee;
  border-radius: 3px;
}

.alert-card.no-messages { 
  background-color: #f1f4f867!important;
  border-top: none;
  color:rgb(206, 208, 218);
}

.alert-message-container { 
  margin-top: 3em;
  padding: 0 1em;
  width: 100%;
}
.alert-message-container h5 { 
  padding: 1em;
  font-weight: bold;
}

.alert-message-box  { 
  display: inline-block;
  margin: .3em 1%;
  width: 30%;
  vertical-align: top;
}
@media only screen and (max-width: 768px) { 
  .alert-message-box  { 
      margin: .3em 2%;
      width: 45%;
  }
}
@media only screen and (max-width: 425px) { 
  .alert-message-box  { 
      display: block;
      margin: .3em auto;
      width: 90%;
  }
}

.alert-message-box .alert-title { 
  padding: .9em .8em;
}

.alert-message-box .alert-action { 
  background-color: var(--warning-color);
  display: block;
  text-align: center;
}

.alert-message-box .alert-action:hover { 
  cursor: pointer;
}

/* :::::::::::::::::::::::::: CARD LAYOUT :::::::::::::::::::::::::::: */

.card-slide-container { 
  position: relative;
  min-height: 28rem;
  overflow-x: hidden;
}

.card-navigation { 
  text-align: center;
  display: flex;
  justify-content: space-between;
}
.card-navigation > span { 
  color: var(--text-color-faded);
}

.slide-item { 
  float: left;
  width: 23rem;
  position: absolute!important;
  right: calc(50% - 12rem);
  transition: all ease-in-out .5s;
}
@media only screen and (max-width: 470px) { 
  .slide-item { 
      width: 100%;
      margin: 0;
      right: 0;
  }
}

.faded-card { 
  opacity: .5;
}
.off-right { 
  transform: translateX(120%);
}
.off-left { 
  transform: translateX(-120%);
}

/* :::::::::::::::::::::::::: MODALS :::::::::::::::::::::::::::: */
.hidden-data-list-container { 
  position: relative;
}
.hidden-data-list-container .hidden-dropdown-container { 
  position: absolute;
  top: 15px;
  left: 10px;
  opacity: 1;
}

.loading-gif {
  width: 20px;
  height: 20px;
}

.modal-paged-form { 
  min-height: calc((100vh - 210px) - 180px);
}

.modal-body.paged-form-container { 
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.title-of-modal {
  display: inline-block!important;
  border-radius: 3px;
  margin: 0 auto;
  padding: .3em 1.5em;
  position: relative;
  bottom: 1em;
  color: white;
  font-weight: bold;
  text-align: center;
  font-family: "Poppins Bold", Arial, Helvetica, sans-serif;
  background-color: var(--color-primary);
}


.modal-footer-button-container { 
  width: 100%;
}
.modal-footer-button-container > * { 
  margin: 0 .2em;
}
@media only screen and (max-width: 425px) { 
  .modal-footer-button-container { 
      display: flex;
      flex-direction: column;
  }
  .modal-footer-button-container > * { 
      margin: .1em 0;
  }
  .modal-footer-button-container > .btn:nth-child(1){ 
      order: 3;
  }
  .modal-footer-button-container > .btn:nth-child(2){ 
      order: 1;
  }
  .modal-footer-button-container > .btn:nth-child(3){ 
      order: 2;
  }
}

.visit-info-container { 
  overflow-y: auto;
  padding: 0 2.5em;
}
.visit-info { 
  border-bottom:  1px solid rgba(0, 0, 0, 0.075);
  margin-bottom: .6rem;
}
.visit-info:hover { 
  background-color: rgba(0, 0, 0, 0.075);
}
.visit-info .label-info { 
  float: right;
}

.block-user-container { 
  border:  1px solid #eee;
  border-radius: 5px;
}

/* :::::::::::::::::::::::::: SETTINGS PANEL :::::::::::::::::::::::::::: */
.settings-container { 
  display: flex;
  padding: 2em 2em 0 0;
}
.settings-nav { 
  margin-right: 2em;
}

.settings-option { 
  padding: .4rem .4rem .4rem 4rem;
}

.settings-option:hover{
  cursor: pointer;
  background-color: #3f81ea34;
  color: white;
}
.settings-option.selected { 
  background-color: #3F80EA;
  color: white;
  border-radius: 0 4px 4px 0;
}
@media only screen and (max-width: 768px) {
  .settings-container { 
      display: block;
      padding: 1em;
  }
  .settings-nav {
      margin: 0;
      text-align: center;
  }
  .settings-nav * { 
      display: inline-block;
  }
  .settings-option { 
      padding-left: .4rem;
  }
  .settings-option.selected { 
      border-radius: 4px;
  }

  .settings-content .default-button { 
      float: none;
      margin-top: 2em;
  }
  .settings-container .separator { 
      display: none;
  }
}

.settings-content { 
  width: 100%;
}

.parameters-container { 
  margin: 2em 0;
}

/* :::::::::::::::::::::::::: MAIN PANEL :::::::::::::::::::::::::::: */
.panel-stats-container { 
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 1em;
  grid-template-areas: 
  "area01 area01 area01 area01 area01";
  /* "area01 area01 area01 area02 area02"; */
}
@media only screen and (max-width: 1024px) { 
  .panel-stats-container {
      grid-template-areas: 
      "area01 area01 area01 area01 area01"
      "area02 area02 area02 area01 area01";
      /* "area01 area01 area01 area01 area01"
      "area02 area02 area02 area02 area02"; */
  }
}

.area01 { 
  grid-area: area01;    
}
.area02 { 
  grid-area: area02;
}


/* .stats-panel { 
  display: flex;
  justify-content: flex-start;
} */

.stats-panel > *  {
  margin: 1em;
}

.stats-panel h1 { 
  font-size: 4rem;
}
.stats-panel .main-box { 
  background-color: #E0EAFC;
  color: #4886EB;
}
.stats-panel .main-box h1 { 
  color: #4886EB;
}

.stats-panel .box { 
  padding: 1em .5em;
}
.stats-panel .box-content { 
  /* padding: 1rem 4.5rem; */
  text-align: center;
}


/* 
::::::::::::::::::::::::::::::::::::::::::::::::::::::
                  MODIFIER CLASSES
::::::::::::::::::::::::::::::::::::::::::::::::::::::
*/

.refresh-btn { 
  padding: .3em .5em;
  color: white;
  font-weight: bolder;
  background-color: #343e4db0;
  border-radius: 5px;
}

.bell-btn { 
  color: white;
  font-weight: bolder;
  background-color: #343e4db0;
  border-radius: 5px;
  padding: .3em;
}

.refresh-btn:hover, .bell-btn:hover { 
  cursor: pointer;
  background-color: var(--default-color);
}

.overflow-y { 
  overflow-y: auto;
}

.text-bold { 
  font-weight: 700;
}

.text-white { 
  color: white;
}

.text-left { 
  text-align: end;
}
.text-no-underline { 
  text-decoration: none;
}

.text-underline { 
  text-decoration: underline;
}

.text-small { 
  font-size: .8rem;
}

.text-highlighted-dark-form { 
  color: orange;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.left-sided-button { 
  float: left;
}

.right-sided-button { 
  float: right;
}

.dark-button { 
  background-color: black;
}

.dark-button:hover {
  background-color: orange;
  color: white;
}

.disabled-dark-button { 
  color: rgba(255, 254, 254, 0.507);
}
.disabled-dark-button:hover{ 
  color: rgba(255, 254, 254, 0.507);
}

.submit-button {
  background-color: orange;
}

.submit-button:hover { 
  background-color: rgb(221, 144, 0);
}


.default-button {
  background-color: #414c5da8;
  color: white;
  padding: .15rem 1rem;
  border-radius: 2px;
  vertical-align: middle;
}
.default-button:hover { 
  background-color: #414c5d;
  cursor: pointer;
}

.required { 
  border-left: 3px solid orange!important;
}

.text-align-start { 
  display: block;
  text-align: start;
}

.text-left { text-align: left!important;}
.text-right { text-align: right!important;}

.small-input  { 
  max-width: 4rem;
}

.ss-inputs > * { 
  padding-bottom: 1em;
}

.separator { 
  height: 3px;
  width: 100%;
  background-color: var(--text-color);
}
.separator.light { 
  opacity: .2;
}
.separator.transparent { 
  opacity: 0;
}
.separator.thin { 
  height: 1px;
}

.bg-faded { 
  background-color: rgba(206, 203, 203, 0.192);
}

.text-yellow { color: var(--warning-color)}
.text-red {color: var(--error-color)}
.text-green { color: var(--success-color)}

.active-option { 
  color: var(--color-secundary)
}

.vertical-middle { 
  vertical-align: middle;
}


.clear-float-both { 
  clear: both;
}

.no-decoration { 
  list-style-type: none;
}

.max-h-40 { 
  max-height: 23rem!important;
  overflow-y: auto;
}

/* :::::::::::::::::::::::::: MATERIAL ICONS :::::::::::::::::::::::::::: */
.material-icons.md-16 {
  font-size: 16px;
}
.material-icons.md-12 {
  font-size: 9px;
}


/* 
::::::::::::::::::::::::::::::::::::::::::::::::::::::
                  SCROLL BAR
::::::::::::::::::::::::::::::::::::::::::::::::::::::
*/
::-webkit-scrollbar {
  width: .2rem;
}

::-webkit-scrollbar-track {
  background: rgba(136, 136, 136, 0.308);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #dbd9d9;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

